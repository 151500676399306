import React from 'react'

import PropTypes from 'prop-types'

import {
  Grid,
} from '@coinspaid-hub/ui-kit'

import styles from './styles'

const PageHeader = ({
  children,
}) => {
  const { header } = styles()

  if (!children) {
    return null
  }

  return (
    <Grid
      container
      alignItems='center'
      className={header}
    >
      {children}
    </Grid>
  )
}

PageHeader.propTypes = {
  children: PropTypes.node,
}

export default PageHeader
