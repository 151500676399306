import React,
{
  useState,
  useEffect,
} from 'react'
import {
  useLocation,
} from 'react-router-dom'

import _get from 'lodash/get'

import { ThemeProvider } from '@coinspaid-hub/ui-web'
import TerminalController from 'controllers/Terminal'

import TerminalView from 'views/Terminal'

import ThemeImage from './ThemeImage'
import Tracking from './Tracking'

const DEFAULT_PARAMS = {
  additional_parameters: {},
  client_id: null,
  is_iframe: 0,
}

function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => {
    const query = new URLSearchParams(search)

    return {
      data: query.get('data'),
      signature: query.get('signature'),
    }
  }, [search])
}

export const decodeBase64 = (hash) => {
  try {
    return JSON.parse(atob(hash))
  } catch (error) {
    return null
  }
}

export const parametersGenerate = ({
  signature,
  ...spread
}) => ({
  headers: {
    ...(signature ? { PTSignature: signature } : {}),
  },
  params: spread,
})

export const getTheme = (client_id, themeList) => {
  if (themeList[client_id]) {
    return themeList[client_id]
  }

  return window.app_config.theme_name
}

export const App = () => {
  const config = window.app_config
  const themeList = config?.themeList || {}
  const isModeSelectorFeature = _get(config, ['features', 'ModeSelector'], false)

  const {
    data,
    signature,
  } = useQuery()

  const search = decodeBase64(data)
  const {
    additional_parameters,
    client_id,
    is_iframe,
  } = search || DEFAULT_PARAMS
  const {
    headers,
    params,
  } = parametersGenerate({
    data,
    signature,
  })
  const clientId = additional_parameters?.client_id || client_id

  const [theme, setTheme] = useState(getTheme(clientId, themeList))

  useEffect(() => {
    setTheme(getTheme(clientId, themeList))
  }, [
    clientId,
    themeList,
  ])

  return (
    <ThemeProvider
      theme={theme}
      enabled={isModeSelectorFeature}
    >
      <TerminalController
        appName={config.app_name}
        headers={headers}
        params={params}
        iframe={is_iframe}
        clientId={clientId}
      >
        <TerminalView />
      </TerminalController>
      <ThemeImage />
      <Tracking />
    </ThemeProvider>
  )
}
