import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useNavigate,
  useParams,
  useLocation,
} from 'react-router-dom'

import {
  i18nTool,
  languageList,
  initialLanguage,
} from 'i18n'
import _get from 'lodash/get'
import {
  getUrlLanguage,
  getLanguage,
  changeDataLocale,
} from 'utils/localisationHelper'

import { Tracker } from '@coinspaid-hub/core/services/Tracking/Analitics'
import { reLocalizeUrl } from '@coinspaid-hub/core/utils/Locales'
import {
  TRACK_EVENT,
  SWITCH_LANGUAGE,
} from 'constants/trackEvents'

const useLanguage = () => {
  const { i18n } = useTranslation()
  const navigate = useNavigate()
  const parameters = useParams()
  const {
    locale,
  } = parameters
  const location = useLocation()
  const {
    search,
  } = location

  const { language } = i18n

  const onChangeLanguage = useCallback(
    async (newLanguage) => {
      const url = _get(parameters, '*', '')
      const urlWithLanguage = reLocalizeUrl(`/${url}${search}`, newLanguage)

      changeDataLocale(newLanguage)
      navigate(urlWithLanguage)
      await i18nTool.changeLanguage(newLanguage)

      Tracker.push([TRACK_EVENT, SWITCH_LANGUAGE, `${language} -> ${newLanguage}`])
    },
    [
      parameters,
      search,
    ],
  )

  const initLanguage = useCallback(
    async () => {
      const urlLanguage = getUrlLanguage(locale)
      const browserLanguage = navigator.language.split('-').join('_')
      const parsedLanguage = getLanguage({
        browserLanguage,
        urlLanguage,
        languageList,
        initialLanguage,
      })

      await onChangeLanguage(parsedLanguage)
    },
    [
      locale,
      onChangeLanguage,
    ],
  )

  useEffect(() => {
    initLanguage()
  }, [])

  return {
    language,
    languageList,
    onChangeLanguage,
  }
}

export default useLanguage
