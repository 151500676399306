import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import template from './template'

const PaymentBody = (props) => {
  const { customMinDeposit } = window.app_config
  const { t } = useTranslation()

  const isWhiteLabel = useMemo(() => props.appName !== 'coinspaid', [props.appName])

  return template(
    {
      ...props,
      t,
    },
    {
      isWhiteLabel,
      customMinDeposit,
    },
  )
}

export default PaymentBody
