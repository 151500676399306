import { makeStyles } from '@mui/styles'

export default makeStyles(({
  palette,
}) => ({
  copyIconClass: {
    '& svg path': {
      fill: palette.basic[600],
    },
  },
}))
