import React from 'react'
import { useRoutes } from 'react-router-dom'

import { App } from './controllers/App'

const routesList = [
  {
    path: ':locale/*',
    element: <App />,
  },
  {
    path: '*',
    element: <App />,
  },
]

const Routes = () => useRoutes(routesList)

export default Routes
