import React from 'react'
import { Trans as TransOrigin, useTranslation } from 'react-i18next'

import PropTypes from 'prop-types'

const Trans = (props) => {
  const {
    ns,
    i18nKey,
    defaults,
    values,
    children,
    components,
  } = props
  const { t } = useTranslation(ns)

  return (
    <TransOrigin
      t={t}
      i18nKey={i18nKey}
      defaults={defaults}
      values={values}
      components={components}
    >
      {children}
    </TransOrigin>
  )
}

Trans.propTypes = {
  ns: PropTypes.any,
  i18nKey: PropTypes.string,
  defaults: PropTypes.string,
  values: PropTypes.any,
  children: PropTypes.node,
  components: PropTypes.any,
}

export default Trans
