import { makeStyles } from '@mui/styles'

export default makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(2),
  },
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing(1),
    color: theme.palette.basic[900],
  },
  text: {
    textAlign: 'center',
    marginBottom: theme.spacing(3),
    color: theme.palette.basic[900],
  },
}))
