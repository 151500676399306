import React, { useContext } from 'react'

import {
  Avatar,
  Typography,
} from '@coinspaid-hub/ui-kit'
import TerminalDataContext from 'controllers/Terminal/TerminalDataContext'

import styles from './styles'

const MerchantName = () => {
  const classes = styles()

  const {
    merchant_name,
    merchant_logo,
  } = useContext(TerminalDataContext)

  if (!merchant_name) {
    return null
  }

  return (
    <>
      {merchant_logo && (
        <Avatar
          variant='circular'
          alt={merchant_name}
          src={merchant_logo}
          className={classes.logo}
        />
      )}
      <Typography
        noWrap
        variant='subtitle2'
      >
        {merchant_name}
      </Typography>
    </>
  )
}

export default MerchantName
