import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import PropTypes from 'prop-types'

import { Tooltip } from '@coinspaid-hub/ui-kit'

const TooltipContainer = React.forwardRef(({
  children,
  ...props
}, ref) => (
  <span
    {...props}
    ref={ref}
  >
    {children}
  </span>
))

TooltipContainer.propTypes = {
  children: PropTypes.node,
}

const CopyToClipboardComponent = ({
  onCopy,
  text,
  open,
  children,
  title,
  placement,
  ...props
}) => (
  <Tooltip
    onClose={onCopy}
    open={open}
    title={title}
    placement={placement}
    {...props}
  >
    <TooltipContainer onClick={onCopy}>
      <CopyToClipboard
        text={text}
      >
        {children}
      </CopyToClipboard>
    </TooltipContainer>
  </Tooltip>
)

CopyToClipboardComponent.propTypes = {
  onCopy: PropTypes.func,
  text: PropTypes.node,
  open: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.node,
  placement: PropTypes.string,
}

export default CopyToClipboardComponent
