import React, { useMemo } from 'react'

import _isNull from 'lodash/isNull'

import { Box, Grid } from '@coinspaid-hub/ui-kit'
import WithIframe from '@coinspaid-hub/ui-web/layouts/WithIframe'

import LanguageSwitcher from 'components/LanguageSwitcher'
import PageHeader from 'components/PageHeader'

import MerchantName from './component/MerchantName'
import ErrorWindowBody from './ErrorWindowBody'
import PaymentBody from './PaymentBody'
import styles from './styles'

export default function template(props) {
  const classes = styles()

  return (
    <Box className={classes.root}>
      <WithIframe
        loading={props.store.loading}
        iframe={props.store.iframe}
        type='app'
      >
        <BodyRender
          store={props.store}
        />
      </WithIframe>
    </Box>
  )
}

const BodyRender = (props) => {
  const {
    loading,
    data,
    error,
    iframe,
    appName,
    clientId,
  } = props.store

  return useMemo(
    () => (!loading && _isNull(error) && data ? (
      <>
        <PageHeader>
          <MerchantName />
          <Grid
            container
            justifyContent='end'
            sx={{
              width: 'unset',
              marginLeft: 'auto',
            }}
          >
            <LanguageSwitcher />
          </Grid>
        </PageHeader>
        <PaymentBody
          data={data}
          iframe={iframe}
          appName={appName}
          clientId={clientId}
        />
      </>
    ) : (
      <ErrorWindowBody
        data={data}
        error={error}
      />
    )),
    [loading, data, error, iframe],
  )
}
