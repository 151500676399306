import React from 'react'

import PropTypes from 'prop-types'
import encodeSvg from 'utils/encodeSvg'

import { Box, QRCode } from '@coinspaid-hub/ui-kit'
import CurrencyIcon from '@processing/currency-icons'

import styles from './styles'

const QrCodeWithIcon = ({
  qrcode,
  currencyIso,
}) => {
  const { qrCodeWrapperClass } = styles()

  return (
    <Box className={qrCodeWrapperClass}>
      <QRCode
        dataTestId='payment-terminal'
        value={qrcode}
        size={170}
        ecLevel='Q'
        quietZone={11}
        logoHeight={40}
        logoWidth={42}
        logoImage={
          encodeSvg(
            <CurrencyIcon
              currency={currencyIso}
            />,
          )
        }
      />
    </Box>
  )
}

QrCodeWithIcon.propTypes = {
  qrcode: PropTypes.any,
  currencyIso: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default QrCodeWithIcon
