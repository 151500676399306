import React, { useRef, useState } from 'react'

import PropTypes from 'prop-types'

import {
  InputAdornment,
  IconButton,
} from '@coinspaid-hub/ui-kit'
import {
  TextField,
} from '@coinspaid-hub/ui-kit/src/lab'
import Icons from '@processing/coinspaid-icons'

import CopyToClipboard from '../CopyToClipboard'

import styles from './styles'

const TIME_TO_SHOW_TOOLTIP = 1000

const InputWithCopy = (props) => {
  const { copyIconClass } = styles()
  const {
    value,
    type,
    dataTestId,
    snackBarMessages,
    ...rest
  } = props

  const [isCopied, setIsCopied] = useState(false)

  const timerIdRef = useRef()
  const onCopy = () => {
    setIsCopied(true)

    clearTimeout(timerIdRef.current)

    timerIdRef.current = setTimeout(
      () => setIsCopied(false),
      TIME_TO_SHOW_TOOLTIP,
    )
  }

  return (
    <TextField
      dataTestId={dataTestId}
      fullWidth
      value={value}
      readOnly
      endAdornment={(
        <InputAdornment position='end'>
          <CopyToClipboard
            title={snackBarMessages[type]}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            text={value}
            open={isCopied}
            placement='top'
            arrow
            onCopy={onCopy}
          >
            <IconButton
              className={copyIconClass}
              size='small'
              dataTestId={type}
            >
              <Icons name='M_Copy' />
            </IconButton>
          </CopyToClipboard>
        </InputAdornment>
      )}
      {...rest}
    />
  )
}

InputWithCopy.propTypes = {
  value: PropTypes.any,
  type: PropTypes.string,
  dataTestId: PropTypes.string,
  snackBarMessages: PropTypes.object,
}

export default InputWithCopy
