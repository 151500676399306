const TRACK_EVENT = 'trackEvent'

const SWITCH_LANGUAGE = 'Switch Language'
const BROWSER_LANGUAGE = 'Browser Language'

export {
  SWITCH_LANGUAGE,
  TRACK_EVENT,
  BROWSER_LANGUAGE,
}
