import React,
{
  useEffect,
  useState,
} from 'react'

import _isUndefined from 'lodash/isUndefined'
import _noop from 'lodash/noop'
import PropTypes from 'prop-types'

import useAxios from 'hooks/useAxios'

import TerminalDataContext from './TerminalDataContext'

const Terminal = (props) => {
  const {
    headers,
    params,
    iframe,
    appName,
    clientId,
    children,
  } = props
  const [store, setStore] = useState({
    loading: true,
    error: null,
    iframe,
    appName,
    clientId,
  })

  const [{
    data,
    loading,
    error,
  }, fetch] = useAxios(
    {
      url: 'address',
      method: 'POST',
      headers,
    },
    { manual: true },
  )

  useEffect(() => {
    setStore((previous) => ({
      ...previous,
      data: data?.data,
      loading,
      ...(!_isUndefined(error) ? { error } : {}),
      iframe,
    }))
  }, [loading, error, data])

  useEffect(() => {
    if (params?.data) {
      fetch({ data: params }).catch(_noop)
      setStore((previous) => ({
        ...previous,
        loading: true,
      }))
    }
  }, [])

  const clonedChildren = React.cloneElement(children, {
    store,
    setStore,
    iframe,
  })

  return (
    <TerminalDataContext.Provider value={store.data}>
      {clonedChildren}
    </TerminalDataContext.Provider>
  )
}

Terminal.propTypes = {
  children: PropTypes.node,
  headers: PropTypes.object,
  params: PropTypes.object,
  iframe: PropTypes.number,
  appName: PropTypes.string,
  clientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default Terminal
