import React from 'react'

import Trans from 'components/Trans'

const COPIED_TEXTS = {
  tag: (
    <Trans
      i18nKey='views:wallet.deposit.tag_copied'
      defaults='Tag copied to clipboard'
    />
  ),
  address: (
    <Trans
      i18nKey='views:wallet.deposit.address_copied'
      defaults='Address copied to clipboard'
    />
  ),
}

export {
  COPIED_TEXTS,
}
