import { useTranslation } from 'react-i18next'

import template from './template'

const ErrorWindow = (props) => {
  const { t } = useTranslation('views')

  return template({
    ...props,
    t,
  })
}

export default ErrorWindow
