import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { Tracker, configure } from '@coinspaid-hub/core/services/Tracking/Analitics'

const Tracking = () => {
  const location = useLocation()

  useEffect(() => {
    configure({
      config: window.app_config?.matomo || {},
    })

    // ethereum detect
    Tracker.push(['setCustomDimension', 1, !!window.ethereum])
  }, [])

  useEffect(() => {
    Tracker.track(location)
  }, [location])

  return null
}

export default Tracking
